<!-- Control Multi-Finder -->

<template>
  <!-- <v-layout class="multi_filter"> -->
  <div style="display:flex">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <!-- Tipo de Busqueda: Texto -->
        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls[elem_menuSeleccionado.ctrl].value"
          :label="elem_menuSeleccionado.label"
          :disabled="disabled"
          @keypress.13="onclick_buscar"
        >
          <template v-slot:prepend-inner>
            <v-icon v-on="on">{{ "mdi-menu-down" }}</v-icon>
          </template>
        </v-text-field>

        <!-- Botón de busqueda -->
        <v-btn v-bind="Entorno.btn" @click="onclick_buscar">
          <v-icon>{{ Entorno.btn.icono }}</v-icon>
        </v-btn>
      </template>

      <!-- Lista de opciones de Busqueda -->
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title @click="onclick_selectBusqueda(item)">
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  <!-- </v-layout> -->
</template>

<script>
export default {
  props: {
    items: { type: [Object, Array], required: true },
    schema: { type: [Object, Array], required: true },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      // variables de configuración
      Entorno: this.$cfg.ctrls.multifilter,

      // variables de componente
      elem_menuSeleccionado: null
    };
  },

  created() {
    if (!this.items) return;
    this.setBusqueda(this.items[0]);
  },

  methods: {
    // actualizo el value del texto introducido en el textfield
    inputTextfield(inputValue) {
      this.onInput(
        inputValue,
        this.schema.ctrls[this.elem_menuSeleccionado.ctrl]
      );
    },

    // selecciono un tipo de busqueda de la lista asociada al textfield.
    // al seleccionarla actualizo el label del textfield y guardo el item
    onclick_selectBusqueda(elem) {
      this.setBusqueda(elem);
    },

    // emito evento buscar para proceder a la busqueda
    onclick_buscar() {
      if (!this.elem_menuSeleccionado) return;
      this.$emit("onEvent", this.elem_menuSeleccionado.buscar);
    },

    // guardo el elemento recibido como elemento actualmente seleccionado
    setBusqueda(elem) {
      this.elem_menuSeleccionado = elem;
    }
  }
};
</script>

<style scope>
/* .multi_filter .boton_busqueda {
  margin: 4px 0px 0px 10px;
} */
</style>
